<template>
    <button
        v-bind="$attrs"
        :class="{
            'base-button__active': isButtonActive,
            'normal': !inverted,
            'inverted': inverted,
        }"
        class="base-button"
        :disabled="disabled"
    >
        <span v-if="isLoading" class="loading-circle"></span>

        <slot v-else></slot>
    </button>
</template>

<script>
export default {
    name: 'BaseButton',
    props: {
        disabled: {type: Boolean, default: false},
        isLoading: {type: Boolean, default: false},
        isButtonActive: {type: Boolean, default: false},
        inverted: {type: Boolean, default: false},
    },
}
</script>

<style scoped>
.base-button {
    @apply flex items-center justify-center shrink-0 w-full border border-[#000000] h-[48px] rounded-[100px] font-[700] text-[14px];

    &:disabled {
        @apply opacity-30;
    }
}

.normal {
    @apply text-[--typography-color-white] bg-[--primary-typography-color]
}

.inverted {
    @apply text-[--primary-typography-color] bg-[--typography-color-white]
}

.base-button__active {
    @apply bg-primary border-primary;
}
</style>
